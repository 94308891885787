<template>
  <div class="call-account-page-wrapper">
    <div class="package-wrapper">
      <div v-for="(item, index) in packageList":key="item.aid" :class="{item: true, active: index === checkedPackage}" @click="checkedPackage = index">
        <template>
          <p class="desc" style="font-size: 18px;">{{ item.value }} 条</p>
          <p class="desc">{{ item.m }}元/条</p>
          <h3 class="title" style="font-size: 18px;margin: 8px -24px -6px -24px;border-top: 1px solid #d9d9d9;padding-top: 4px;">
            <span class="unit">¥</span>
            <span>{{ item.key }}</span>
          </h3>
        </template>
      </div>
    </div>
    <div class="pay-container">
      <div class="lside">
        <a-row>
          <a-col>
            <div class="term"> 支付金额 </div>
            <div class="content"> <h3 class="price"><span class="unit">¥</span><span>{{ payMoney }}</span></h3> </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <div class="term"> 支付方式 </div>
            <div class="content">
              <div class="pay-type">
                <i aria-label="图标: wechat" tabindex="-1" class="anticon anticon-wechat item-icon active">
                  <svg
                    viewBox="64 64 896 896"
                    data-icon="wechat"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    focusable="false"
                    class=""><path d="M690.1 377.4c5.9 0 11.8.2 17.6.5-24.4-128.7-158.3-227.1-319.9-227.1C209 150.8 64 271.4 64 420.2c0 81.1 43.6 154.2 111.9 203.6a21.5 21.5 0 0 1 9.1 17.6c0 2.4-.5 4.6-1.1 6.9-5.5 20.3-14.2 52.8-14.6 54.3-.7 2.6-1.7 5.2-1.7 7.9 0 5.9 4.8 10.8 10.8 10.8 2.3 0 4.2-.9 6.2-2l70.9-40.9c5.3-3.1 11-5 17.2-5 3.2 0 6.4.5 9.5 1.4 33.1 9.5 68.8 14.8 105.7 14.8 6 0 11.9-.1 17.8-.4-7.1-21-10.9-43.1-10.9-66 0-135.8 132.2-245.8 295.3-245.8zm-194.3-86.5c23.8 0 43.2 19.3 43.2 43.1s-19.3 43.1-43.2 43.1c-23.8 0-43.2-19.3-43.2-43.1s19.4-43.1 43.2-43.1zm-215.9 86.2c-23.8 0-43.2-19.3-43.2-43.1s19.3-43.1 43.2-43.1 43.2 19.3 43.2 43.1-19.4 43.1-43.2 43.1zm586.8 415.6c56.9-41.2 93.2-102 93.2-169.7 0-124-120.8-224.5-269.9-224.5-149 0-269.9 100.5-269.9 224.5S540.9 847.5 690 847.5c30.8 0 60.6-4.4 88.1-12.3 2.6-.8 5.2-1.2 7.9-1.2 5.2 0 9.9 1.6 14.3 4.1l59.1 34c1.7 1 3.3 1.7 5.2 1.7a9 9 0 0 0 6.4-2.6 9 9 0 0 0 2.6-6.4c0-2.2-.9-4.4-1.4-6.6-.3-1.2-7.6-28.3-12.2-45.3-.5-1.9-.9-3.8-.9-5.7.1-5.9 3.1-11.2 7.6-14.5zM600.2 587.2c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9c0 19.8-16.2 35.9-36 35.9zm179.9 0c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9a36.08 36.08 0 0 1-36 35.9z"></path></svg>
                </i>
                <i aria-label="图标: alipay" tabindex="-1" class="anticon anticon-alipay item-icon active">
                  <svg
                    viewBox="64 64 896 896"
                    data-icon="alipay"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    focusable="false"
                    class=""><path d="M789 610.3c-38.7-12.9-90.7-32.7-148.5-53.6 34.8-60.3 62.5-129 80.7-203.6H530.5v-68.6h233.6v-38.3H530.5V132h-95.4c-16.7 0-16.7 16.5-16.7 16.5v97.8H182.2v38.3h236.3v68.6H223.4v38.3h378.4a667.18 667.18 0 0 1-54.5 132.9c-122.8-40.4-253.8-73.2-336.1-53-52.6 13-86.5 36.1-106.5 60.3-91.4 111-25.9 279.6 167.2 279.6C386 811.2 496 747.6 581.2 643 708.3 704 960 808.7 960 808.7V659.4s-31.6-2.5-171-49.1zM253.9 746.6c-150.5 0-195-118.3-120.6-183.1 24.8-21.9 70.2-32.6 94.4-35 89.4-8.8 172.2 25.2 269.9 72.8-68.8 89.5-156.3 145.3-243.7 145.3z"></path></svg>
                </i>
              </div>
            </div>
          </a-col>
        </a-row>
        <p class="desc">说明：适用于国内短信，验证码类、通知类、营销类三合一，不包括国际及港澳台地区消息服务</p>
        <p class="desc">请先阅读并同意支付相关协议后进行支付</p>
        <div>
          <a-checkbox v-model="isAgreement" style="line-height:2">
            <span style="color:rgba(0,0,0,.85)">我已阅读并同意</span> <a href="https://yufabu.tiantianhuoke.com/member/login/pages?aid=27" target="_blank">《短信资源包购买协议》</a>
          </a-checkbox>
        </div>
      </div>
    </div>
    <div class="footer-btn-group" v-if="hasSubmitBtn">
      <a-button :loading="btnLoading" type="primary" @click="handleSubmit">
        提交订单
      </a-button>
    </div>
    <!--支付二维码-->
    <a-modal v-model:visible="payOpen" @cancel="payClose" width="520px" append-to-body style="padding: 0px">
      <div class="pay-erweima">
        <vue-qr
          :text="pay_url"
          :correctLevel="3"
          :size="470"
          :margin="10"
          :logoMargin="3"
        ></vue-qr>
        <a-row type="flex" justify="center">
          <a-col :span="1">
            <h2>
              <i data-v-a64f862c="" aria-label="图标: scan" class="anticon anticon-scan">
                <svg
                  viewBox="64 64 896 896"
                  data-icon="scan"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class=""><path d="M136 384h56c4.4 0 8-3.6 8-8V200h176c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H196c-37.6 0-68 30.4-68 68v180c0 4.4 3.6 8 8 8zm512-184h176v176c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V196c0-37.6-30.4-68-68-68H648c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zM376 824H200V648c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v180c0 37.6 30.4 68 68 68h180c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm512-184h-56c-4.4 0-8 3.6-8 8v176H648c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h180c37.6 0 68-30.4 68-68V648c0-4.4-3.6-8-8-8zm16-164H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"></path></svg>
              </i>
            </h2>
          </a-col>
          <a-col :span="7"><h2>手机扫码支付</h2></a-col>
          <a-col :span="1">
            <h2>
              <i aria-label="图标: wechat" tabindex="-1" class="anticon anticon-wechat item-icon active">
                <svg
                  viewBox="64 64 896 896"
                  data-icon="wechat"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class=""><path d="M690.1 377.4c5.9 0 11.8.2 17.6.5-24.4-128.7-158.3-227.1-319.9-227.1C209 150.8 64 271.4 64 420.2c0 81.1 43.6 154.2 111.9 203.6a21.5 21.5 0 0 1 9.1 17.6c0 2.4-.5 4.6-1.1 6.9-5.5 20.3-14.2 52.8-14.6 54.3-.7 2.6-1.7 5.2-1.7 7.9 0 5.9 4.8 10.8 10.8 10.8 2.3 0 4.2-.9 6.2-2l70.9-40.9c5.3-3.1 11-5 17.2-5 3.2 0 6.4.5 9.5 1.4 33.1 9.5 68.8 14.8 105.7 14.8 6 0 11.9-.1 17.8-.4-7.1-21-10.9-43.1-10.9-66 0-135.8 132.2-245.8 295.3-245.8zm-194.3-86.5c23.8 0 43.2 19.3 43.2 43.1s-19.3 43.1-43.2 43.1c-23.8 0-43.2-19.3-43.2-43.1s19.4-43.1 43.2-43.1zm-215.9 86.2c-23.8 0-43.2-19.3-43.2-43.1s19.3-43.1 43.2-43.1 43.2 19.3 43.2 43.1-19.4 43.1-43.2 43.1zm586.8 415.6c56.9-41.2 93.2-102 93.2-169.7 0-124-120.8-224.5-269.9-224.5-149 0-269.9 100.5-269.9 224.5S540.9 847.5 690 847.5c30.8 0 60.6-4.4 88.1-12.3 2.6-.8 5.2-1.2 7.9-1.2 5.2 0 9.9 1.6 14.3 4.1l59.1 34c1.7 1 3.3 1.7 5.2 1.7a9 9 0 0 0 6.4-2.6 9 9 0 0 0 2.6-6.4c0-2.2-.9-4.4-1.4-6.6-.3-1.2-7.6-28.3-12.2-45.3-.5-1.9-.9-3.8-.9-5.7.1-5.9 3.1-11.2 7.6-14.5zM600.2 587.2c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9c0 19.8-16.2 35.9-36 35.9zm179.9 0c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9a36.08 36.08 0 0 1-36 35.9z"></path></svg>
              </i>
            </h2>
          </a-col>
          <a-col :span="2">
            <h2>
              <i aria-label="图标: alipay" tabindex="-1" class="anticon anticon-alipay item-icon active">
                <svg
                  viewBox="64 64 896 896"
                  data-icon="alipay"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class=""><path d="M789 610.3c-38.7-12.9-90.7-32.7-148.5-53.6 34.8-60.3 62.5-129 80.7-203.6H530.5v-68.6h233.6v-38.3H530.5V132h-95.4c-16.7 0-16.7 16.5-16.7 16.5v97.8H182.2v38.3h236.3v68.6H223.4v38.3h378.4a667.18 667.18 0 0 1-54.5 132.9c-122.8-40.4-253.8-73.2-336.1-53-52.6 13-86.5 36.1-106.5 60.3-91.4 111-25.9 279.6 167.2 279.6C386 811.2 496 747.6 581.2 643 708.3 704 960 808.7 960 808.7V659.4s-31.6-2.5-171-49.1zM253.9 746.6c-150.5 0-195-118.3-120.6-183.1 24.8-21.9 70.2-32.6 94.4-35 89.4-8.8 172.2 25.2 269.9 72.8-68.8 89.5-156.3 145.3-243.7 145.3z"></path></svg>
              </i>
            </h2>
          </a-col>
        </a-row>
      </div>
    </a-modal>

  </div>
</template>

<script>
import { getSmsSetMeal, createSmsSetMealOrder, getSmsSetMealOrder } from '@/api/sms/zrwinfo'
import vueQr from 'vue-qr'
export default {
  name: 'Recharge',
  data() {
    const paySettings = {
      value: 1,
      types: [
        { title: '微信支付', icon: 'wechat', value: 1 },
        { title: '支付宝支付', icon: 'alipay', value: 2 }
      ]
    }
    return {
      payOpen: false,
      paySettings,
      isAgreement: false,
      moduleId: 4,
      btnLoading: false,
      checkedPackage: '', // 选中的套餐
      orderSn: '',
      packageList: [],
      pay_url: '',
      order_id: '',
      timer: null
    }
  },
  components: {
    vueQr
  },
  props: {
    channelId: {
      type: String
    },
    hasSubmitBtn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    payMoney() {
      return this.packageList.length ? this.packageList[this.checkedPackage].key : ''
    }
  },
  created() {
    this.getSmsSetMeal()
  },
  methods: {
    getSmsSetMeal() {
      this.moduleId = 4
      this.packageList = []
      getSmsSetMeal().then(res => {
        if (res.code == 1) {
          this.packageList = res.data
          this.checkedPackage = 0
        }
      })
    },
    handleSubmit() {
      if (this.timer) {
        window.clearInterval(this.timer)
        this.timer = null
      }
      const { checkedPackage, packageList, isAgreement } = this
      if (!packageList.length) return false
      if (!isAgreement) { return this.$message.warning('请先阅读并同意支付相关协议') }
      this.btnLoading = true
      const packageId = packageList[checkedPackage].aid // 套餐aid
      const money = packageList[checkedPackage].key // 套餐金额
      createSmsSetMealOrder(this.channelId, packageId, money).then(res => {
        this.btnLoading = false
        if (res.code == 1) {
          this.payOpen = true
          this.pay_url = res.data.url
          this.order_id = res.data.order_id
          console.log(this.timer)
          if (!this.timer) {
            this.timer = window.setInterval(() => {
              setTimeout(this.paySuccess(this.order_id), 0)
            }, 2000)
          }
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.btnLoading = false
      })
    },
    payClose() {
      if (this.timer) {
        window.clearInterval(this.timer)
      }
      this.payOpen = false
    },
    paySuccess(orderId) {
      getSmsSetMealOrder(orderId).then(res => {
        if (res.code == 1) {
          if (res.data.status == 1) {
            this.payOpen = false
            this.$message.success('支付成功')
            this.$parent.getAccountInfo()
            if (this.timer) {
              window.clearInterval(this.timer)
            }
          }
        }
      })
    }
  }
}
</script>

<style  scoped>
.pay-erweima{
  text-align: center;
}
.call-account-page-wrapper .package-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.call-account-page-wrapper .package-wrapper .item.active, .call-account-page-wrapper .package-wrapper .item:hover {
  border-color: #1890ff;
  -webkit-box-shadow: 0 0 0 2px rgba(0,82,199,.2);
  box-shadow: 0 0 0 2px rgba(0,82,199,.2);
}

.call-account-page-wrapper .package-wrapper .item.active .desc, .call-account-page-wrapper .package-wrapper .item.active .title, .call-account-page-wrapper .package-wrapper .item:hover .desc, .call-account-page-wrapper .package-wrapper .item:hover .title {
  color: #1890ff;
}
.call-account-page-wrapper .package-wrapper .item {
  border: 1px solid #e6eaf1;
  border-radius: 4px;
  padding: 12px 24px;
  margin: 8px;
  -webkit-transition: all .2s cubic-bezier(.55,.055,.675,.19);
  transition: all .2s cubic-bezier(.55,.055,.675,.19);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 168px;
  text-align: center;
}
.call-account-page-wrapper .package-wrapper .item .desc {
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(0,0,0,.45);
}
.call-account-page-wrapper .package-wrapper .item .title {
  font-size: 24px;
  margin-bottom: 4px;
  -webkit-transition: all .2s cubic-bezier(.55,.055,.675,.19);
  transition: all .2s cubic-bezier(.55,.055,.675,.19);
}
.call-account-page-wrapper h3.title {
  font-size: 16px;
  margin: 8px -24px -4px -24px;
  border-top: 1px solid #e6eaf1;
  padding-top: 4px;
}
.call-account-page-wrapper .package-wrapper .item .unit {
  font-size: 16px;
}
.call-account-page-wrapper h3.title {
  font-size: 16px;
  margin: 8px -24px -4px -24px;
  border-top: 1px solid #e6eaf1;
  padding-top: 4px;
}
.call-account-page-wrapper .pay-container {
  background-color: #F6F9FD;
  border-radius: 4px;
  padding: 24px;
  margin: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.call-account-page-wrapper .pay-container .price {
  font-size: 24px;
  margin-bottom: 4px;
  margin-top: 0px;
  color: #f5222d;
}

.call-account-page-wrapper .pay-container .term {
  color: rgba(0,0,0,.85);
  display: table-cell;
  line-height: 20px;
  margin-right: 8px;
  padding-bottom: 16px;
  white-space: nowrap;
  vertical-align: top;
}

.call-account-page-wrapper .pay-container .content {
  color: rgba(0,0,0,.65);
  display: table-cell;
  min-height: 22px;
  line-height: 22px;
  padding-bottom: 16px;
  width: 100%;
  word-break: break-all;
  padding-left: 10px;
}
.call-account-page-wrapper .pay-container .pay-type .item-icon.active {
  color: #1890ff;
}
.call-account-page-wrapper .pay-container .pay-type .item-icon {
  font-size: 24px;
  color: rgba(0,0,0,.45);
  -webkit-transition: all .2s cubic-bezier(.55,.055,.675,.19);
  transition: all .2s cubic-bezier(.55,.055,.675,.19);
  margin-right: 12px;
  cursor: pointer;
}
.call-account-page-wrapper .pay-container .desc {
  color: rgba(0,0,0,.45);
  margin-top: 24px;
  font-size: 14px;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color .3s;
  transition: color .3s;
  -webkit-text-decoration-skip: objects;
}
.footer-btn-group {
  width: 100%;
  padding: 8px 16px 16px;
  background: #fff;
  text-align: center;
  z-index: 10;
}
::v-deep .el-dialog__body{
  padding: 10px !important;
}
</style>
